import React, { useState } from 'react';

const Hover = ({initial, hover, defaultState = false}) => {
  const [hovered, setHovered] = useState(defaultState);
  return (
    <div 
      style={{
        display: 'flex',
        width: '7rem',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        cursor: 'pointer',
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(defaultState)}
    >
      <span>
        {hovered ? hover : initial}
      </span>
    </div>

  );
};

export default Hover;