import React from 'react';
import '../assets/css/remove-padding.css';
import '../assets/css/defaults.css';
import './css/Layout.css';
import SEO from '../components/Seo';
import { Link } from 'gatsby';
import Hover from './Hover';

const Layout = ({
  title = 'coloc',
  children,
  leftLinks = ['about', 'articles', 'arty'],
  keywords = ['blog', 'coloc', 'travel', 'city', 'guides'],
  page
}) => (
  <>
    <SEO
      title={title}
      page={page}
      keywords={keywords}
    />
    <div id="main-content">
      <div className="header">
        <h1 className="title"><Link to={'/'}>{title}</Link></h1>
      </div>
      <div className="left-column">
        <div className="stack">
          {[
            ['AMS', 'Amsterdam'],
            ['MAD', 'Madrid'],
            ['MTL', 'Montreal'], 
            ['ATH', 'Athens'], 
            ['RAK', 'Marrakesh'], 
            ['NBO', 'Kenya'], 
            ['ADD', 'Ethiopia'], 
            ['BLQ', 'Bologna'], 
            ['PER', 'Perth'],
            ['REK', 'Reykjavik'],
            ['TAO', 'Tsingtao'],
            ['PAR', 'Paris'],
            ['NYC', 'New York'],
            ['MEL', 'Melbourne'],
            ['NRT', 'Tokyo']
          ].map(([code, city]) => <Hover key={code} initial={code} hover={`${city} soon`} />)}
        </div>
      </div>
      <div className="centre">{children}</div>
      <div className="right-column">
        <div className="stack">
          {leftLinks.map(h => <Link key={h} to={`/${h}`}>{`${h}.`}</Link>)}
        </div>
      </div>

    </div>
  </>
);

export default Layout;
